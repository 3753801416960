import { getUniversalHelpers } from "../helpers";

export function hello__hello(p: { example: string }) {
  console.log("Hello " + Date.now());
  return `Hello World - ${Math.random().toString()}`;
}
hello__hello.auth = async (req: any) => {};
hello__hello.adminAuth = async (req: any) => {};

export async function hello__olliePipe(p: { example: string }) {
  // TODO_REWORK_THINGS
  // await getOlliePipe().sendAnalyticsEvent({ analyticsType: "hello", details: { test: `${Math.random()}` } });
  return `Hello World ollie pipe - ${Math.random().toString()} `;
}
hello__olliePipe.auth = async (req: any) => {};
hello__olliePipe.adminAuth = async (req: any) => {};

export async function hello__helloFb(p: { example: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const id = h.Example.generateId();
  await h.Example.add({ doc: { id, name: "foo", age: Math.random() } });

  return `Hello World - ${id} - ${Math.random().toString()}`;
}
hello__helloFb.auth = async (req: any) => {};
hello__helloFb.adminAuth = async (req: any) => {};

// i18n certified - complete
